import * as React from 'react';
import styled from 'styled-components';
import { useRef } from 'react';
import { useSprings, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import clamp from 'lodash.clamp';

const OuterWrapper = styled.div`
  position: relative;
  min-height: 385px;
  box-shadow: 0px 1px 6px 0px #00000040;
`;

const Page = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  touch-action: none;
`;
const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 385px;
`;

function Viewpager({ pages, label }) {
  const index = useRef(0);
  const width = 258;

  const [props, api] = useSprings(pages.length, (i) => ({
    x: i * width,
    scale: 1,
    display: 'block',
  }));

  function handleDotClick(newIndex) {
    const currentIndex = index.current;
    if (newIndex === currentIndex) {
      return;
    }
    const mx = newIndex === 1 ? -258 : 258;
    api.start((i) => {
      const active = index.current !== newIndex;
      const x = (i - index.current) * width + (active ? mx : 0);
      const scale = 1;
      return { x, scale, display: 'block' };
    });
  }

  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], cancel }) => {
      if (active && Math.abs(mx) > width / 2) {
        index.current = clamp(
          index.current + (xDir > 0 ? -1 : 1),
          0,
          pages.length - 1
        );
        cancel();
      }
      api.start((i) => {
        if (i < index.current - 1 || i > index.current + 1)
          return { display: 'none' };
        const x = (i - index.current) * width + (active ? mx : 0);
        const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
        return { x, scale, display: 'block' };
      });
    }
  );

  if (pages.length === 0) {
    return null
  }

  return (
    <OuterWrapper>
      {label}
      <Wrapper>
        {props.map(({ x, display, scale }, i) => (
          <Page {...bind()} key={i} style={{ display, x }}>
            <animated.div style={{ scale, height: '100%' }}>
              {React.cloneElement(
                pages[i],
                { isAnimated: true, handleDotClick },
                null
              )}
            </animated.div>
          </Page>
        ))}
      </Wrapper>
    </OuterWrapper>
  );
}

export default Viewpager;
