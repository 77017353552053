import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import CopyButton from '../CTAButtons/CopyButton';
import SendButton from '../CTAButtons/SendButton';

import { media } from 'styles/theme';

const StyledBottomButtons = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.nearWhite};
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.15);
  display: flex;
  height: 125px;
  justify-content: center;
  gap: 8px;
  flex-direction: column;

  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;

  @media screen and (min-width: 1397px) {
    display: none;
  }

  ${media.ns} {
    gap: 20px 10px;
    flex-direction: row;
    height: 100px;
  }
`;

function BottomButtons({ handleShowModal, t }) {
  return (
    <StyledBottomButtons>
      <CopyButton t={t} />
      <SendButton t={t} onClick={handleShowModal} />
    </StyledBottomButtons>
  );
}

BottomButtons.propTypes = {};

BottomButtons.defaultProps = {};

export default BottomButtons;
