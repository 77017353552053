import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function GoogleMeet() {
  return (
    <StaticImage src="../../../../images/google_meet_small.png" height={22} />
  );
}

GoogleMeet.propTypes = {};

GoogleMeet.defaultProps = {};

export default GoogleMeet;
