import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import CiscoImage from './CertifiedSolutions/Cisco';
import GoogleMeetImage from './CertifiedSolutions/GoogleMeet';
import MSTImage from './CertifiedSolutions/MST';
import ZoomImage from './CertifiedSolutions/Zoom';

function useProducts(t) {
  const productTypes = {
    microphone: {
      bgColor: '#429AD9',
      title: t('recommendations:microphone'),
    },
    wireless: {
      bgColor: '#d6001c',
      title: t('products:labelWireless'),
    },
    loudspeaker: {
      bgColor: '#D73189',
      title: t('products:labelLoudspeaker'),
    },
    processor: {
      bgColor: '#26609B',
      title: t('products:labelDSP'),
    },
    software: {
      bgColor: '#935CC2',
      title: t('products:labelSoftware'),
    },
    microphoneSpeaker: {
      bgColor: '#269b61',
      title: t('products:labelMicSpeaker'),
    },
  };

  const solutions = {
    zoom: {
      alt: 'zoom',
      image: <ZoomImage height={24} />,
    },
    mst: {
      alt: 'microsoft teams',
      image: <MSTImage />,
    },
    cisco: {
      alt: 'cisco',
      image: <CiscoImage />,
    },
    meet: {
      alt: 'meet',
      image: <GoogleMeetImage />,
    },
  };
  const imageProps = {
    layout: 'fullWidth',
    maxHeight: 178,
  };

  const products = {
    // speakers
    mxn5: {
      desc: t('products:loudspeaker'),
      image: (
        <StaticImage
          alt="MXN5wC"
          src="../../../images/mxn5wc.png"
          {...imageProps}
        />
      ),
      solutions: [
        solutions['mst'],
        solutions['zoom'],
        solutions['cisco'],
        solutions['meet'],
      ],
      title: 'MXN5',
      type: productTypes.loudspeaker,
    },
    // processors
    p300: {
      desc: t('products:conferencingProcessor'),
      image: (
        <StaticImage
          alt="P300"
          src="../../../images/p300.png"
          {...imageProps}
        />
      ),
      solutions: [
        solutions['mst'],
        solutions['zoom'],
        solutions['cisco'],
        solutions['meet'],
      ],
      title: 'P300',
      type: productTypes.processor,
    },
    intellimix: {
      desc: 'Intellimix Room',
      image: (
        <StaticImage 
          alt="Intellimix Room" 
          src="../../../images/intellimix.png" 
          {...imageProps} 
        />
      ),
      solutions: [solutions['mst']],
      title: 'Intellimix Room',
      type: productTypes.processor,
    },
    aniusb: {
      desc: t('products:usbNetworkInterface'),
      image: 
        <StaticImage 
          src="../../../images/aniusb.png" 
          alt="ANIUSB" 
          {...imageProps} 
        />,
      solutions: [],
      title: 'ANIUSB-MATRIX',
      type: productTypes.processor,
    },
    // microphones + speakers
    mxa901: {
      desc: t('products:integratedCeilingArray'),
      image: (
        <StaticImage
          alt="MXA901"
          src="../../../images/mxa901.png"
          {...imageProps}
        />
      ),
      solutions: [],
      title: 'MXA901',
      type: productTypes.microphoneSpeaker,
    },
    mxa902: {
      desc: t('products:integratedCeilingArray'),
      image: (
        <StaticImage
          alt="MXA902"
          src="../../../images/mxa902.png"
          {...imageProps}
        />
      ),
      solutions: [],
      title: 'MXA902',
      type: productTypes.microphoneSpeaker,
    },
    // microphones
    mxa920: {
      desc: t('products:ceilingMic'),
      image: <StaticImage alt="MXA920" src="../../../images/920.png" {...imageProps} />,
      solutions: [solutions['mst']],
      title: 'MXA920',
      type: productTypes.microphone,
    },
    'qlx-d': {
      desc: t('products:wirelessMic'),
      image: <StaticImage alt="QLX-D" src="../../../images/mxw.png" {...imageProps} />,
      solutions: [],
      title: 'QLX-D',
      type: productTypes.microphone,
    },
    mxw: {
      desc: t('products:wirelessMic'),
      image: <StaticImage alt="MXW" src="../../../images/mxw.png" {...imageProps} />,
      solutions: [],
      title: 'MXW',
      type: productTypes.wireless,
    },
    "mxw next 2": {
      desc: t('products:wirelessMic'),
      image: <StaticImage alt="MXW neXt 2" src="../../../images/mxwnext2.png" {...imageProps} />,
      solutions: [],
      title: 'MXW neXt 2',
      type: productTypes.wireless,
    },
    mxa310: {
      desc: t('products:tableMic'),
      image: (
        <StaticImage
          alt="MXA310"
          src="../../../images/310.png"
          {...imageProps}
        />
      ),
      solutions: [solutions['mst'], solutions['zoom'], solutions['cisco']],
      title: 'MXA310',
      type: productTypes.microphone,
    },
    'mxa710-2ft': {
      desc: t('products:linearMic'),
      image: (
        <StaticImage
          alt="MXA710-2ft"
          src="../../../images/mxa710-2ft.png"
          {...imageProps}
        />
      ),
      solutions: [solutions['mst'], solutions['cisco']],
      title: 'MXA710 2ft',
      type: productTypes.microphone,
    },
    'mxa710-4ft': {
      desc: t('products:linearMic'),
      image: (
        <StaticImage
          alt="MXA710-4ft"
          src="../../../images/mxa710-4ft.png"
          {...imageProps}
        />
      ),
      solutions: [solutions['mst'], solutions['cisco']],
      title: 'MXA710 4ft',
      type: productTypes.microphone,
    },
    // software
    designer: {
      desc: t('products:assetSoftware'),
      image: <StaticImage alt="Designer" src="../../../images/designer.png" {...imageProps} />,
      solutions: [solutions['zoom']],
      title: 'Designer',
      type: productTypes.software,
    },
    systemAPI: {
      desc: t('products:assetSoftware'),
      image: (
        <StaticImage
          alt="SystemAPI"
          src="../../../images/systemapi.png"
          {...imageProps}
        />
      ),
      solutions: [],
      title: 'SystemAPI',
      type: productTypes.software,
    },
  };
  return { productData: products, productTypes };
}
export default useProducts;
