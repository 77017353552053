import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from 'components/Text';

const StyledBlock = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 12px 20px;
  :last-of-type {
    border-bottom: none;
  }
`;

const Category = styled(Text)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
`;

function Block({ category, children, show }) {
  if (!show) {
    return null;
  }
  return (
    <StyledBlock>
      {category && <Category>{category}</Category>}
      {children}
    </StyledBlock>
  );
}

Block.propTypes = {
  category: PropTypes.string,
  children: PropTypes.any,
  show: PropTypes.bool,
};

Block.defaultProps = {
  category: '',
  children: null,
  show: true,
};

export default Block;
