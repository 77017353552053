import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import Button from 'components/Button';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import useIsMobile from 'hooks/useIsMobile';
import { sendGTM } from 'utils/helpers';
import { media } from 'styles/theme';

const StyledBanner = styled.div`
  align-items: center;
  display: flex;
  height: 76px;
  position: relative;
  overflow: hidden;

  ${media.sm} {
    a {
      font-size: 14px;
      height: 30px;
      width: 94px;
    }
  }

  ${media.ns} {
    height: 107px;
  }
`;

const BgWrapper = styled.div`
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const Left = styled.div``;

const Row = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 15px;
  position: relative;
  z-index: 1;

  ${media.ns} {
    padding: 0 40px 0 30px;
  }
`;

function Banner({ t }) {
  const isMobile = useIsMobile();
  const imageQuery = useStaticQuery(graphql`
    query bannerImageQuery {
      bannerImage: file(relativePath: { eq: "solutions-banner-bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO]
            layout: FULL_WIDTH
          )
        }
      }
    }
  `);
  const bannerImage = getImage(imageQuery.bannerImage);
  return (
    <StyledBanner>
      <Row>
        <Left>
          {isMobile && (
            <StaticImage
              alt="logo"
              className="logo-mobile"
              height={43}
              src="../../../images/solutions-logo.png"
            />
          )}
          {!isMobile && (
            <StaticImage
              alt="logo"
              className="logo-desktop"
              height={60}
              src="../../../images/solutions-logo.png"
            />
          )}
        </Left>
        <Button
          as="a"
          href={t('recommendations:productLink')}
          onClick={() => sendGTM('roomdesigner-clicked_ecosystem')}
          rel="noopener noreferrer"
          outline={true}
          target="_blank"
        >
          {t('recommendations:explore')}
        </Button>
      </Row>
      <BgWrapper>
        <GatsbyImage
          alt="background"
          image={bannerImage}
          style={{ height: '100%' }}
        />
      </BgWrapper>
    </StyledBanner>
  );
}

Banner.propTypes = {};

Banner.defaultProps = {};

export default Banner;
