import React from 'react';
import PropTypes from 'prop-types';
import Checked from './Checked';
import UnChecked from './UnChecked';

import styled from 'styled-components';

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  margin-bottom: 20px;
  svg {
    margin-right: 12px;
  }
  div {
    height: 22px;
  }
  user-select: none;
  ${({ theme }) => theme.sizes.smallP}
`;

function Checkbox({ checked, label, labelStyle, ...rest }) {
  const svgProps = { height: 22 };
  return (
    <Label style={labelStyle}>
      <div>
        {checked ? <Checked {...svgProps} /> : <UnChecked {...svgProps} />}
      </div>
      {label}
      <CheckboxInput type="checkbox" checked={checked} {...rest} />
    </Label>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  labelStyle: PropTypes.object,
};

Checkbox.defaultProps = {
  labelStyle: {},
};

export default Checkbox;
