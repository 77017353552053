import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function MST() {
  return (
    <StaticImage
      src="../../../../images/certified-microsoft-teams.png"
      alt={'microsoft teams'}
      height={34}
    />
  );
}

MST.propTypes = {};

MST.defaultProps = {};

export default MST;
