import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useQueryParam, StringParam } from 'use-query-params';
import { useI18next } from 'gatsby-plugin-react-i18next';

const isBrowser = typeof window !== 'undefined';

function useQuizData() {
  const { language } = useI18next();
  const [recommendations, setRecommendations] = React.useState([]);
  const quizData = useStaticQuery(query);
  const [roomSize] = useQueryParam('roomSize', StringParam);
  const [seating] = useQueryParam('seating', StringParam);
  const [micPlacement] = useQueryParam('micPlacement', StringParam);

  React.useEffect(() => {
    if (isBrowser && valuesPresent()) {
      const rows = quizData.allQuizDataCsv.nodes;
      const foundRow = rows.find(
        (row) =>
          row.Room_Size === roomSize &&
          row.Seating === seating &&
          row.Mic_Placement === micPlacement
      );
      const formatted = formatRecommendations(foundRow);
      setRecommendations(formatted);
    }
    // eslint-disable-next-line
  }, []);

  function getQty(str) {
    const [name, qty] = str.split('(');
    if (qty) {
      return { name: name.trim(), qty: parseInt(qty.split(')')[0]) };
    }
    return { name: name.trim(), qty: 1 };
  }

  function getOptions(rowOptions) {
    let mic, processor, speaker, micSpeaker;
    const options = rowOptions.split(',').map((el) =>
      el.trim()
    );

    // mic / processor / speaker
    if (options.length === 3) {
      [mic, processor, speaker] = options

      return {
        microphone: getQty(mic),
        processor: processor,
        speaker: getQty(speaker),
      }
    }

    // mic + speaker / processor
    [micSpeaker, processor] = options

    return {
      micSpeaker: getQty(micSpeaker),
      processor: getQty(processor),
    }
  }

  function formatRecommendations(row) {
    if (language !== 'zh-CN') {
      if (row.Option_2) {
        return [
          getOptions(row.Option_1),
          getOptions(row.Option_2)
        ]
      } else {
        return [getOptions(row.Option_1)]
      }
    }

    return [getOptions(row.China_Option)]
  }

  function valuesPresent() {
    return roomSize !== null && seating !== null && micPlacement !== null;
  }

  return { quizData, recommendations };
}

export default useQuizData;

const query = graphql`
  query AllQuizDataTwo {
    allQuizDataCsv {
      nodes {
        Room_Size
        Seating
        Mic_Placement
        Option_1
        Option_2
        China_Option
      }
    }
  }
`;
