import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { media } from 'styles/theme';

import Layout from 'components/Layout';
import Modal from 'components/Modal';
import Title from 'components/Title';

import Banner from 'pageComponents/recommendations/Banner';
import BottomButtons from 'pageComponents/recommendations/BottomButtons';
import CTAButtons from 'pageComponents/recommendations/CTAButtons';
import Form from 'pageComponents/recommendations/Form';
import PageLayout from 'pageComponents/recommendations/PageLayout';
import Products from 'pageComponents/recommendations/Products';
import YourAnswers from 'pageComponents/recommendations/YourAnswers';

import useQuizData from 'pageComponents/recommendations/useQuizData';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Left = styled.div`
  grid-area: answers;
  order: 1;
  ${media.ns} {
    order: 0;
  }
`;

const Right = styled.div`
  grid-area: products;
  order: 0;
  ${media.ns} {
    order: 1;
  }
`;

const StyledTitle = styled(Title)`
  grid-area: title;
  margin-bottom: 27px;
  margin-top: 0px;

  ${media.ns} {
    margin-top: 30px;
    margin-bottom: 44px;
  }
`;

const RecommendationsTitle = styled.h3`
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  font-family: "DIN2014-Bold";
`

const RecommendationsSeparator = styled.hr`
  margin-top: 34px;
  height: 1px; 
  border: 0;
  background-color: #D4D4D4;
  color: #D4D4D4;
`

const Empty = styled.div`
  grid-area: empty;
`;

function Recommendations(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { recommendations } = useQuizData();

  const { i18n, t } = useTranslation([
    'applications',
    'common',
    'industries',
    'roomSize',
    'nav',
    'recommendations',
    'shareForm',
    'products',
  ]);

  function openModal() {
    setModalOpen(true);
  }

  function getBottomNav() {
    if (modalOpen) {
      return null;
    }
    return <BottomButtons t={t} handleShowModal={openModal} />;
  }
  return (
    <Layout bottomNav={getBottomNav()} navProps={{ hideNav: true }}>
      <PageLayout>
        <Empty />
        <StyledTitle
          subTitle={t('common:globalSubTitle')}
          title={t('recommendations:title')}
        />
        <Left>
          <YourAnswers t={t} />
        </Left>
        <Right>
          <CTAButtons handleShowModal={openModal} t={t} lang={i18n?.language} />
          <Banner t={t} />
          {recommendations.map((recommendationSet, index) => {
            return (
              <div key={index}>
                <RecommendationsTitle>{t('common:solution')} #{index + 1} </RecommendationsTitle>
                <Products recommendations={recommendationSet} t={t} />
                {recommendations.length !== index + 1 && (
                  <RecommendationsSeparator />
                )}
              </div>
            )
          })}
        </Right>
      </PageLayout>
      <Modal closeModal={() => setModalOpen(false)} modalOpen={modalOpen}>
        <Form t={t} onClose={() => setModalOpen(false)} />
      </Modal>
    </Layout>
  );
}

Recommendations.propTypes = {};

Recommendations.defaultProps = {};

export default Recommendations;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: {
          in: [
            "applications"
            "common"
            "industries"
            "microphonePlacement"
            "nav"
            "other"
            "products"
            "recommendations"
            "roomSize"
            "seating"
            "shareForm"
          ]
        }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
