const arr = [
  'amusement',
  'arts',
  'banking',
  'building',
  'school',
  'construction',
  'emergency',
  'energy',
  'design',
  'environment',
  'national',
  'gov',
  'healthService',
  'healthcare',
  'hospitality',
  'mfg',
  'mil',
  'ngo',
  'nonProfit',
  'primarySchool',
  'consulting',
  'professional',
  'realEstate',
  'regional',
  'retail',
  'secondarySchool',
  'state',
  'tech',
  'training',
  'logistics',
  'transportation',
  'university',
];

export default arr;
