import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Button';
import CopyButton from './CopyButton';
import SendButton from './SendButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 46px;

  @media screen and (max-width: 1396px) {
    display: none;
  }
`;

// This is used in CopyButton and SendButtonc
export const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  height: 41px;
  font-size: ${({ $lang }) => ($lang === 'ja' ? 10 : 16)}px;
  padding: 0;
  width: 278px;
`;

function CTAButtons({ handleShowModal, lang, t }) {
  return (
    <Wrapper>
      <CopyButton $lang={lang} t={t} />
      <SendButton
        $lang={lang}
        t={t}
        onClick={handleShowModal}
        style={{ marginTop: 10 }}
      />
    </Wrapper>
  );
}

CTAButtons.propTypes = {
  handleShowModal: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

CTAButtons.defaultProps = {};

export default CTAButtons;
