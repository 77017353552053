import * as React from 'react';
// import PropTypes from 'prop-types';

function ArrowIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M22 12l-20 12 7.289-12-7.289-12z" />
    </svg>
  );
}

ArrowIcon.propTypes = {};

ArrowIcon.defaultProps = {};

export default ArrowIcon;
