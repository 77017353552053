import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Button';
import Text from 'components/Text';

const StyledFormSuccess = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 52px 40px 42px;
`;

function FormSuccess({ onClose, t }) {
  return (
    <StyledFormSuccess>
      <Text center font="dinBold" size="f3">
        {t('shareForm:submitted')}
      </Text>
      <Text center size="smallP" style={{ marginBottom: 20 }}>
        {t('shareForm:subtitle')}
      </Text>
      <Button onClick={onClose} style={{ width: 210 }}>
        {t('shareForm:close')}
      </Button>
    </StyledFormSuccess>
  );
}

FormSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

FormSuccess.defaultProps = {};

export default FormSuccess;
