import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Label, StyledInput } from 'components/Input';

const SelectWrapper = styled(StyledInput)`
  background-color: white;
  cursor: pointer;
  padding: 0;
  margin-top: 4px;
  position: relative;
  :focus-within {
    border-color: ${({ theme }) => theme.colors.black};
  }
  select {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: inherit;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    height: 50px;
    margin: 0;
    padding: 13px;
    line-height: inherit;
    outline: none;
    width: 100%;
  }
  select::-ms-expand {
    display: none;
  }
`;

const Container = styled.div`
  margin: 10px 0;
`;

function Select({ children, label, required, placeholder, ...rest }) {
  return (
    <Container>
      <Label>
        {label}
        {required && '*'}
      </Label>
      <SelectWrapper as="div">
        <select required={required} {...rest}>
          {placeholder && <option value={placeholder}>{placeholder}</option>}
          {children}
        </select>
      </SelectWrapper>
    </Container>
  );
}

Select.propTypes = {
  children: PropTypes.array.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
};

Select.defaultProps = {
  label: null,
  required: false,
};

export default Select;
