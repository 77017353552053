import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Button';
import Block from './Block';
import Text from 'components/Text';

import { ArrayParam, StringParam, useQueryParam } from 'use-query-params';
import { sendGTM } from 'utils/helpers';
import { sizes } from 'styles/theme';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const StyledYourAnswers = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: none;
  width: 100%;

  @media screen and (min-width: ${sizes.md}) {
    display: block;
  }
`;

const Answer = styled(Text)`
  margin: 0;
  text-transform: capitalize;
  ${({ theme }) => theme.sizes.smallP}
`;

const Title = styled(Text)`
  transform: translateY(1px);
  ${({ theme }) => theme.fonts.dinBold}
`;

const StyledButton = styled(Button)`
  ${({ theme }) => theme.fonts.ostrichBold}
  svg,
  path {
    transition: all 0.3s ease-in-out;
  }
  :hover {
    svg path {
      fill: #fff;
    }
  }
`;

function YourAnswers() {
  const { t } = useTranslation([
    'applications',
    'common',
    'microphonePlacement',
    'seating',
    'nav',
  ]);
  const [videoPlatform] = useQueryParam('videoConferencing', ArrayParam);
  const [roomSize] = useQueryParam('roomSize', StringParam);
  const [seating] = useQueryParam('seating', StringParam);
  const [micPlacement] = useQueryParam('micPlacement', StringParam);
  const [applications] = useQueryParam('applications', ArrayParam);
  const [certifiedSolutions] = useQueryParam('certifiedSolutions', ArrayParam);

  const showApplications = applications?.length > 0;

  const platformMap = {
    cisco: 'Cisco',
    googleMeet: 'Google Meet',
    microsoftTeams: 'Microsoft Teams',
    other: t('common:other'),
    none: t('common:none'),
    zoom: 'Zoom',
  };

  const applicationsText = applications
    ?.map((el) => t(`applications:${el}`))
    .join(', ');

  const videoPlatformText = videoPlatform
    ?.map((el) => platformMap[el])
    .join(', ');

  return (
    <StyledYourAnswers>
      <Block>
        <Title>{t('recommendations:yourAnswers')}</Title>
      </Block>
      <Block category={t('recommendations:videoPlatform')}>
        <Answer>{videoPlatformText}</Answer>
      </Block>
      <Block category={t('nav:certifiedSolutions')}>
        <Answer>{t(`certifiedSolutions:${certifiedSolutions}`)}</Answer>
      </Block>
      <Block category={t('nav:roomSize')}>
        <Answer style={{ textTransform: 'none' }}>
          {t(`roomSize:${roomSize}`)}
        </Answer>
      </Block>
      <Block category={t('nav:seating')}>
        <Answer>{t(`seating:${seating}`)}</Answer>
      </Block>
      <Block category={t('recommendations:microphone')}>
        <Answer>{t(`microphonePlacement:${micPlacement}`)}</Answer>
      </Block>
      <Block category={t('nav:applications')} show={showApplications}>
        <Answer>{applicationsText}</Answer>
      </Block>
      <StyledButton
        onClick={() => sendGTM('start over')}
        secondary
        to="/"
        style={{ width: '100%' }}
      >
        {t('recommendations:reset')}
      </StyledButton>
    </StyledYourAnswers>
  );
}

YourAnswers.propTypes = {};

YourAnswers.defaultProps = {};

export default YourAnswers;
