import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function Cisco() {
  return (
    <StaticImage
      src="../../../../images/certified-cisco.png"
      alt={'cisco partner'}
      height={30}
    />
  );
}

Cisco.propTypes = {};

Cisco.defaultProps = {};

export default Cisco;
