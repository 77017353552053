import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/theme';

import Product, { Label } from './Product';
import Swiper from './Swiper';

import useProducts from './useProducts';

const StyledProducts = styled.div`
  display: grid;
  grid-gap: 28px;
  grid-template-columns: repeat(auto-fit, 258px);
  justify-content: center;
  margin-top: 38px;

  ${media.ns} {
    margin-top: 20px;
    grid-template-columns: repeat(auto-fit, 258px);
    justify-content: start;
  }
`;

function Products({ recommendations, t }) {
  const { productData, productTypes } = useProducts(t);

  const products = []
  const processors = []

  if (recommendations?.microphone && recommendations?.speaker) {
    products.push("microphone")
    products.push("speaker")
    processors.push(productData.p300)
    processors.push(productData.intellimix)
  }

  if (recommendations?.micSpeaker  && recommendations?.processor) {
    products.push("micSpeaker")
    products.push("processor")
  }

  if (products.length === 0 && processors.length === 0) {
    return null
  }

  const processorLabel = (
    <Label $bgColor={productTypes.processor.bgColor}>
      {productTypes.processor.title}
    </Label>
  );

  const softwareLabel = (
    <Label $bgColor={productTypes.software.bgColor}>
      {productTypes.software.title}
    </Label>
  );

  const productPages = products.map((product, index) => {
    const name = recommendations[product].name.toLowerCase()
    const qty = recommendations[product].qty

    return <Product key={index} product={productData[name]} qty={qty} />
  })

  const processorPages = processors.map((processor, index) =>
    <Product index={index} product={processor} qty={1} />
  )

  const softwarePages = [
    <Product index={0} product={productData.systemAPI} qty={1} />,
    <Product index={1} product={productData.designer} qty={1} />,
  ];

  return (
    <StyledProducts>
      {productPages}
      <Swiper pages={processorPages} label={processorLabel} />
      <Swiper pages={softwarePages} label={softwareLabel} />
    </StyledProducts>
  );
}

Products.propTypes = {};

Products.defaultProps = {};

export default Products;
