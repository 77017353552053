import React from 'react';
// import PropTypes from 'prop-types';

function Checked(props) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.75"
        y="1.75"
        width="19.25"
        height="19.25"
        fill="#000"
        stroke="#000"
        strokeWidth="1"
      />
      <path
        d="M6.241 10.072h0a.671.671 0 01.517.245l-.517-.245zm0 0H5.128c-.225 0-.367.26-.217.444h0l4.363 5.39h0a.67.67 0 001.034 0h0l7.78-9.612s0 0 0 0c.153-.186.007-.444-.216-.444H16.76c-.2 0-.392.09-.517.245 0 0 0 0 0 0L9.79 14.064m-3.55-3.992l3.55 3.992m0 0l-3.033-3.746 3.033 3.746z"
        fill="#fff"
        stroke="#fff"
        strokeWidth=".3"
      />
    </svg>
  );
}

Checked.propTypes = {};

Checked.defaultProps = {};

export default Checked;
