import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CertifiedSolutions from '../CertifiedSolutions';
import IndexDots from './IndexDots';
import Text from 'components/Text';

const StyledProduct = styled.div`
  ${({ $isAnimated }) =>
    !$isAnimated && 'box-shadow: 0px 1px 6px 0px #00000040'};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  min-height: 385px;
  position: relative;
`;

export const Label = styled.div`
  align-items: center;
  background-color: ${({ $bgColor }) => $bgColor};
  box-sizing: border-box;
  color: white;
  display: flex;
  height: 33px;
  font-size: 13px;
  font-weight: 400;
  justify-content: center;
  letter-spacing: 0.25em;
  min-width: 133px;
  padding: 2px 7px 0;
  text-align: center;

  position: absolute;
  text-transform: uppercase;
  top: 13px;
  left: -12px;
  z-index: 1;
`;

const Bottom = styled.div`
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 22px 20px;
  user-select: none;
`;

const ImageSection = styled.div`
  height: 178px;
  position: relative;
  img {
    pointer-events: none;
  }
`;

const Title = styled(Text)`
  font-size: 28px;
  margin-bottom: 10px;
  ${({ theme }) => theme.fonts.ostrichBold}
`;

const Gray = styled(Text)`
  color: #6e6e70;
`;

function Product({ handleDotClick, index, isAnimated, product, qty, ...rest }) {
  return (
    <StyledProduct $isAnimated={isAnimated} {...rest}>
      {!isAnimated && (
        <Label $bgColor={product.type.bgColor}>{product.type.title}</Label>
      )}
      <ImageSection>
        {index !== null && (
          <IndexDots handleDotClick={handleDotClick} index={index} />
        )}
        {product.image}
      </ImageSection>
      <Bottom>
        <Title>{product.title}</Title>
        <Gray size="f6">{product.desc}</Gray>
        <Gray size="f6">Qty: {qty}</Gray>
        <CertifiedSolutions solutions={product.solutions} />
      </Bottom>
    </StyledProduct>
  );
}

Product.propTypes = {
  handleDotClick: PropTypes.func,
  isAnimated: PropTypes.bool,
  index: PropTypes.number,
};

Product.defaultProps = {
  handleDotClick: () => null,
  isAnimated: false,
  index: null,
};

export default Product;
