import styled from 'styled-components';
import { sizes } from 'styles/theme';

const PageLayout = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title'
    'products';
  padding: 47px 16px 0px;

  @media screen and (min-width: ${sizes.md}) {
    grid-template-areas:
      'empty   title'
      'answers products';
    grid-template-columns: 264px 1fr;
    grid-template-rows: 130px auto;
    grid-gap: 29px;
    padding: 37px 46px 50px 33px;
  }
`;

export default PageLayout;
