import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCertifiedSolutions = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  margin-right: 5px;
  margin-bottom: 5px;
`;

function CertifiedSolutions({ solutions }) {
  return (
    <StyledCertifiedSolutions>
      {solutions.map((el) => (
        <Wrapper key={el.alt}>{el.image}</Wrapper>
      ))}
    </StyledCertifiedSolutions>
  );
}

CertifiedSolutions.propTypes = {
  solutions: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      image: PropTypes.any.isRequired,
    })
  ),
};

CertifiedSolutions.defaultProps = {};

export default CertifiedSolutions;
