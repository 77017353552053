import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DURATION = 300;

const Overlay = styled.div`
  background-color: ${({ modalOpen }) =>
    modalOpen ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0)'};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: ${({ modalOpen }) => (modalOpen ? 'auto' : 'none')};
  visibility: ${({ mounted }) => (mounted ? 'visible' : 'hidden')};
  z-index: 3;
`;

const Inner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow: auto;
  flex: 1;
  opacity:  ${({ modalOpen }) => (modalOpen ? 1 : 0)};
  transform: translateY(${({ modalOpen }) => (modalOpen ? '0px' : '50px')});
  transition: transform ${DURATION}ms ease-out, opacity ${DURATION}ms ease-out};
  z-index: 4;
`;

function Modal({ children, modalOpen }) {
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    if (modalOpen) {
      setMounted(true);
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.left = '0px';
      document.body.style.right = '0px';
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.left = '';
      document.body.style.right = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      setTimeout(() => {
        setMounted(false);
      }, DURATION);
    }
  }, [modalOpen]);

  // if (!mounted) {
  //   return null;
  // }

  return (
    <Overlay modalOpen={modalOpen} mounted={mounted}>
      <Inner modalOpen={modalOpen} mounted={mounted}>
        {children}
      </Inner>
    </Overlay>
  );
}

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
};

Modal.defaultProps = {};

export default Modal;
