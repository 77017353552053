import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const StyledInput = styled.input`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  box-sizing: border-box;
  display: flex;
  height: 50px;
  font-size: 16px;
  padding: 13px;
  outline: 0;
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.black};
  }
  ${({ theme }) => theme.fonts.dinRegular}
`;

function Input({ label, required, ...rest }) {
  return (
    <InputWrapper>
      <Label>
        {label}
        {required && '*'}
      </Label>
      <StyledInput required={required} {...rest} />
    </InputWrapper>
  );
}

Input.propTypes = {
  required: PropTypes.bool,
};

Input.defaultProps = {
  required: false,
};

export default Input;
