import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledIndexDots = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
`;

const Dot = styled.button`
  background-color: #fff;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  height: 10px;
  margin: 0 3px;
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
  padding: 0;
  pointer-events: auto;
  width: 10px;
`;

function IndexDots({ handleDotClick, index }) {
  return (
    <StyledIndexDots>
      <Dot $active={index === 0} onClick={() => handleDotClick(0)} />
      <Dot $active={index === 1} onClick={() => handleDotClick(1)} />
    </StyledIndexDots>
  );
}

IndexDots.propTypes = {
  handleDotClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

IndexDots.defaultProps = {};

export default IndexDots;
