import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import jsonp from 'jsonp';
import { gtmSendToShureExpert, sendGTM } from 'utils/helpers';

import Button from 'components/Button';
import CloseButton from './CloseButton';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import FormSuccess from './FormSuccess';
import Select from 'components/Select';
import Text from 'components/Text';

import countries from 'components/Select/countries';
import industries from 'components/Select/industries';

const StyledForm = styled.form`
  padding: 52px 40px 42px;
`;

const FormWrapper = styled.div`
  background-color: #f2f2f4;
  display: flex;
  flex-direction: column;
  max-width: 756px;
  position: relative;
  margin-bottom: 25px;
`;

const CheckboxWrapper = styled.div`
  margin-top: 22px;
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  width: 100%;
`;

function Form({ t, onClose }) {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [industry, setIndustry] = React.useState('');
  const [receiveMarketing, setReceiveMarketing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  function addMeta(formParams) {
    const urlParams = new URLSearchParams(window.location.search);
    const medium = urlParams.get('medium');
    const campaign = urlParams.get('campaign');
    const content = urlParams.get('content');
    if (medium) {
      formParams.append('medium', medium);
      formParams.append('campaign', campaign);
      formParams.append('content', content);
    }
    return formParams;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    let formParams = new URLSearchParams();
    formParams.append('fname', firstName);
    formParams.append('lname', lastName);
    formParams.append('email', email);
    formParams.append('country', country);
    formParams.append('company', company);
    formParams.append('industry', industry);
    formParams.append('optin', receiveMarketing);
    formParams.append('link', window.location.href);
    formParams.append('source', window.location.href);
    formParams = addMeta(formParams);

    const jsonpConfig = { timeout: 5000, name: 'shareFormCallback' };
    try {
      jsonp(
        `https://p.shure.com/l/428102/2021-05-06/d5m5bg?${formParams.toString()}`,
        jsonpConfig,
        (err, data) => {
          if (err) {
            setError('An error has occurred while submitting your results.');
          } else {
            // handle success
            onSuccess();
          }
          setLoading(false);
        }
      );
    } catch (err) {
      setError('An error has occurred while submitting your results.');
      setLoading(false);
    }
  }

  function onSuccess() {
    gtmSendToShureExpert();
    sendGTM('roomdesigner-form_submitted', {
      result_url: window.location.href,
    });
    setSuccess(true);
  }

  return (
    <FormWrapper>
      <CloseButton onClose={onClose} />
      {success && <FormSuccess onClose={onClose} t={t} />}
      {!success && (
        <StyledForm onSubmit={handleSubmit}>
          <Text center font="dinBold" size="f3">
            {t('shareForm:title')}
          </Text>
          <Text center size="smallP" style={{ marginBottom: 20 }}>
            {t('shareForm:subtitle')}
          </Text>
          <Input
            label={t('shareForm:firstName')}
            name="first_name"
            required
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          <Input
            label={t('shareForm:lastName')}
            name="last_name"
            required
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
          <Input
            label={t('shareForm:email')}
            name="email"
            required
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            value={email}
          />
          <Select
            label={t('shareForm:country')}
            onChange={(e) => setCountry(e.target.value)}
            name="country"
            required
            placeholder={t('shareForm:selectPlaceholder')}
            value={country}
          >
            {countries.map((el) => (
              <option key={el} value={el}>
                {el}
              </option>
            ))}
          </Select>
          <Input
            label={t('shareForm:company')}
            name="company"
            onChange={(e) => setCompany(e.target.value)}
            value={company}
          />
          <Select
            label={t('shareForm:industry')}
            onChange={(e) => setIndustry(e.target.value)}
            name="industry"
            placeholder={t('shareForm:selectPlaceholder')}
            value={industry}
          >
            {industries.map((el) => (
              <option key={el} value={t(`industries:${el}`)}>
                {t(`industries:${el}`)}
              </option>
            ))}
          </Select>
          <CheckboxWrapper>
            <Checkbox
              checked={receiveMarketing}
              label={t('shareForm:marketing')}
              onChange={() => setReceiveMarketing(!receiveMarketing)}
            />
          </CheckboxWrapper>
          <Text size="smallestP">{t('shareForm:disclaimer')}</Text>
          {error && (
            <Text size="smallestP" style={{ color: '#FF0000' }}>
              {error}
            </Text>
          )}
          <StyledButton
            disabled={loading}
            loading={loading}
            type="submit"
            style={{ position: 'relative' }}
          >
            {t('shareForm:submit')}
          </StyledButton>
        </StyledForm>
      )}
    </FormWrapper>
  );
}

Form.propTypes = {
  onClose: PropTypes.func,
  t: PropTypes.func.isRequired,
};

Form.defaultProps = {
  onClose: () => null,
};

export default Form;
