import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCloseButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green};
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;

  height: 30px;
  width: 30px;

  position: absolute;
  top: 0;
  right: 0;
`;

function CloseButton({ onClose }) {
  return (
    <StyledCloseButton onClick={onClose}>
      <svg
        viewBox="0 0 10 10"
        height={13}
        style={{ transform: 'rotate(45deg)' }}
      >
        <line x1="0" x2="10" y1="5" y2="5" strokeWidth="2" stroke="black" />
        <line x1="5" x2="5" y1="0" y2="10" strokeWidth="2" stroke="black" />
      </svg>
    </StyledCloseButton>
  );
}

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

CloseButton.defaultProps = {};

export default CloseButton;
