import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import LinkIcon from './LinkIcon';
import { StyledButton } from './';

import { gtmCopyAndShare, isBrowser } from 'utils/helpers';
import { useSpring, animated } from '@react-spring/web';

const ButtonWrapper = styled.div`
  position: relative;
`;

const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const TextWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 16px;
  padding-top: 1px;
`;

const TextList = styled(animated.div)`
  display: flex;
  flex-direction: column;
`;

const TextItem = styled.span`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
`;

function CopyButton({ $lang, t }) {
  const [showCopied, setShowCopied] = React.useState(false);

  const styles = useSpring({ y: showCopied ? -16 : 0 });

  function handleCopiedClick() {
    if (showCopied) {
      return;
    }
    gtmCopyAndShare();
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 2000);
  }

  //
  return (
    <ButtonWrapper>
      <CopyToClipboard
        onCopy={handleCopiedClick}
        text={isBrowser ? document.location.href : ''}
      >
        <StyledButton $lang={$lang}>
          <InnerWrapper>
            <TextWrapper>
              <TextList style={styles}>
                <TextItem>{t('recommendations:share')}</TextItem>
                <TextItem>{t('recommendations:copied')}</TextItem>
              </TextList>
            </TextWrapper>{' '}
            <LinkIcon height={10} style={{ marginLeft: 8 }} />
          </InnerWrapper>
        </StyledButton>
      </CopyToClipboard>
    </ButtonWrapper>
  );
}

CopyButton.propTypes = {};

CopyButton.defaultProps = {};

export default CopyButton;
